<!--@Time : 2022/5/26 15:37-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<v-app id="inspire">
		<AppBar></AppBar>
		<Main></Main>
		<Footer></Footer>
	</v-app>
</template>

<script>
import AppBar from "./components/AppBar";
import Main from "./components/Main";
import Footer from "./components/Footer";
export default {
	name: "Layout",
	components: { Footer, Main, AppBar },
};
</script>

<style scoped>
</style>