import request from "../../utils/request";


// 温度
export async function listTemperature(params) {
    return request({
    url: '/api/tuya/statistics/temperature/',
    method: 'get',
    params: params,
  })
}

// 功率
export async function listPower(params) {
    return request({
    url: '/api/tuya/statistics/power/',
    method: 'get',
    params: params,
  })
}


export async function listDouble(params) {
    return request({
    url: '/api/tuya/statistics/double/',
    method: 'get',
    params: params,
  })
}