<template>
  <!-- 当用户点击 Dialog 外部时，需要处理 @click:outside 事件，将 $store.state.showSettingsDialog 设置为 false.
否则，下次就无法再通过 $store.state.showSettingsDialog 触发此 Dialog 打开了。  -->
  <div>
    <v-dialog v-model="Status" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="justify-center">
          <div class="panel-title font-title">操作面板</div>
        </v-card-title>

        <v-card-text>
          <!-- 顶栏 -->
          <!--<Top></Top>-->
          <v-row align="center" justify="center">
            <v-col cols="1" class="pb-0 d-flex align-center">
              <v-btn
                  outlined
                  color="primary"
                  @click="synchronousDialog = true"
              >
                <v-icon left> mdi-cloud-download </v-icon>
                获取
              </v-btn>
            </v-col>
            <v-col cols="2" class="pb-0">
              <v-select
                  v-model="community"
                  :items="communityList"
                  item-text="name"
                  item-value="id"
                  label="小区"
                  clearable
                  return-object
                  @change="getAssetList('floor', community, 2)"
              />
            </v-col>
            <v-col cols="1" class="pb-0">
              <v-select
                  v-model="floor"
                  :items="floorList"
                  item-text="name"
                  item-value="id"
                  label="楼栋"
                  clearable
                  return-object
                  @change="getAssetList('unit', floor, 3)"
              />
            </v-col>
            <v-col cols="1" class="pb-0">
              <v-select
                  v-model="unit"
                  :items="unitList"
                  item-text="name"
                  item-value="id"
                  label="单元"
                  clearable
                  return-object
                  @change="getAssetList('room', unit, 4)"
              />
            </v-col>
            <v-col cols="2" class="pb-0">
              <v-select
                  v-model="room"
                  :items="roomList"
                  item-text="name"
                  item-value="id"
                  label="房间"
                  clearable
                  return-object
                  @change="getEquipmentList(room)"
              />
            </v-col>
            <v-col cols="1" class="pb-0" style="height: 46px">
              <v-text-field
                  dense
                  label="数据读取与计算间隔"
                  suffix="s"
                  v-model="interval"
              />
            </v-col>
            <v-col cols="3" class="pb-0">
              <v-autocomplete
                  v-model="select"
                  :loading="loading"
                  :items="items"
                  item-value="id"
                  item-text="text"
                  :search-input.sync="search"
                  hide-no-data
                  clearable
                  label="设备"
                  placeholder=""
                  return-object
                  @click:clear="clearChoose('all')"
              />
            </v-col>
            <v-col cols="1" class="pb-0 d-flex justify-end align-center">
              <v-btn outlined color="primary" @click="getEquipmentStatus(select)">
                <v-icon left> mdi-cloud-search </v-icon>选择
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <!-- 左栏 -->
            <v-col cols="3">
              <v-card outlined style="min-height: calc(100vh - 222px)">
                <v-card-title>
                  <v-icon color="primary" class="mr-3">mdi-tune</v-icon>
                  <span class="font-title">参数修正</span>
                </v-card-title>

                <v-subheader>
                  <v-icon class="mr-2"> mdi-compass </v-icon>朝向
                </v-subheader>

                <v-card-text>
                  <v-row>
                    <v-col cols="3" class="py-0">
                      <v-text-field dense label="朝向" prefix="≥" suffix="°" v-model="parameters.direction1"/>
                    </v-col>

                    <v-col cols="3" class="py-0">
                      <v-text-field dense label="朝向" prefix="≤" suffix="°" v-model="parameters.direction2"/>
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-text-field dense label="区间内朝向修正" suffix="℃" v-model="parameters.adjustmentDirection"/>
                    </v-col>
                  </v-row>

                  <v-row align="center" justify="center">
                    <v-col cols="6" class="py-0">
                      <p>其他朝向</p>
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-text-field label="其它朝向修正" suffix="℃" v-model="parameters.adjustmentRoomDirection"/>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-subheader>
                  <v-icon class="mr-2">mdi-sun-wireless </v-icon>太阳辐射
                </v-subheader>
                <v-card-text>
                  <v-row>
                    <v-col cols="6" class="py-0">
                      <v-text-field label="太阳辐射" prefix="≥" suffix="W/m²" v-model="parameters.solarRadiation"/>
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-text-field label="太阳辐射修正" suffix="℃" v-model="parameters.adjustmentSolarRadiation_1"/>
                    </v-col>
                  </v-row>

                  <v-row align="center" justify="center">
                    <v-col cols="6" class="py-0">
                      <p>其他辐射</p>
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-text-field label="其他辐射修正" suffix="℃" v-model="parameters.adjustmentSolarRadiation_2"/>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-subheader><v-icon class="mr-2">mdi-weather-windy-variant </v-icon>风速</v-subheader>
                <v-card-text>
                  <v-row>
                    <v-col cols="6" class="py-0">
                      <v-text-field label="风速" prefix="≥" suffix="m/s" v-model="parameters.windSpeed"/>
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-text-field label="风速修正" suffix="℃" v-model="parameters.adjustmentWindSpeed_1"/>
                    </v-col>
                  </v-row>

                  <v-row align="center" justify="center">
                    <v-col cols="6" class="py-0">
                      <p>其他风速</p>
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-text-field
                          label="其他风速修正"
                          suffix="℃"
                          v-model="
													parameters.adjustmentWindSpeed_2
												"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- 中栏 -->
            <v-col cols="6" v-if="refresh">
              <v-row>
                <v-col cols="12">
                  <v-card elevation="3">
                    <v-card-title>
                      <v-icon
                          color="primary"
                          class="mr-3"
                      >
                        mdi-dlna
                      </v-icon>
                      <span class="font-title"
                      >模式设定</span
                      >
                      <v-spacer />
                      <v-radio-group
                          row
                          mandatory
                          class="mt-0 py-0"
                          style="margin-bottom: -14px"
                          v-model="pattern"
                      >
                        <v-radio
                            label="模式1"
                            color="primary"
                            value="M1"
                        />
                        <v-radio
                            label="模式2"
                            color="primary"
                            value="M2"
                        />
                        <v-radio
                            label="模式3"
                            color="primary"
                            value="M3"
                        />
                      </v-radio-group>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="6" class="py-0">
                          <v-text-field label="最低室温保障" suffix="℃" v-model="temp"/>
                        </v-col>
                        <v-col cols="6" class="py-0">
                          <v-text-field label="用户最大电功率" suffix="W" readonly v-model="currentMax"/>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-card outlined style="min-height: calc(100vh - 372px)">
                    <v-card-title>
                      <v-icon color="primary" class="mr-3">mdi-cast-audio</v-icon>
                      <span class="font-title">传感器</span>

                      <v-spacer />

                      <v-radio-group
                          readonly
                          v-model="qn.pirState0"
                          row
                          label="人感"
                          class="mt-0 py-0"
                          style="margin-bottom: -14px"
                      >
                        <v-radio label="有人" color="success" :value="true"/>
                        <v-radio label="无人" color="error" :value="false"/>
                      </v-radio-group>
                    </v-card-title>

                    <v-card-text>
                      <v-row>
                        <v-col cols="4" class="py-0">
                          <v-text-field disabled label="当前已用电功率" suffix="W" v-model="zndb.dqdl"/>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-title>
                      <v-icon color="primary" class="mr-3">mdi-radiator
                      </v-icon>
                      <span class="font-title">电暖器</span>
                      <v-spacer />
                      <v-radio-group readonly v-model="qn.switch" row label="设备状态" class="mt-0 py-0" style="margin-bottom: -14px">
                        <v-radio label="开机" color="success" :value="true"/>
                        <v-radio label="关机" color="error" :value="false"/>
                      </v-radio-group>
                    </v-card-title>

                    <v-card-text>
                      <v-row>

                        <v-col cols="4" class="py-0">
                          <v-text-field disabled label="设定温度" suffix="℃" v-model="qn.tempSet"/>
                        </v-col>

                        <v-col cols="4" class="py-0">
                          <v-text-field disabled label="室内温度" suffix="℃" v-model="qn.tempCurrent"/>
                        </v-col>

                        <v-col cols="4" class="py-0">
                          <v-text-field disabled label="时间" suffix="" v-model="qn.time"/>
                        </v-col>

                        <v-col cols="4" class="py-0">
                          <v-text-field disabled label="功率" suffix="W" v-model="qn.workPower"/>
                        </v-col>

                        <v-col cols="4" class="py-0">
                          <v-text-field disabled label="耗电量" suffix="kWh" v-model="qn.powerConsumption"/>
                        </v-col>

                        <v-col cols="4" class="py-0">
                          <v-text-field disabled label="需求功率" suffix="W" v-model="qn.power_effective"/>
                        </v-col>

                      </v-row>
                    </v-card-text>

                    <v-card-title>
                      <v-icon
                          color="primary"
                          class="mr-3"
                      >
                        mdi-cloud-tags
                      </v-icon>
                      <span class="font-title"
                      >云程序</span
                      >

                      <v-spacer />

                      <v-radio-group
                          readonly
                          v-model="cloudProgram.status"
                          row
                          label="通讯状态"
                          class="mt-0 py-0"
                          style="margin-bottom: -14px"
                      >
                        <v-radio
                            label="连接"
                            color="success"
                            :value="true"
                        />
                        <v-radio
                            label="断开"
                            color="error"
                            :value="false"
                        />
                      </v-radio-group>
                    </v-card-title>

                    <v-card-text>
                      <v-row>

                        <v-col cols="4" class="py-0">
                          <v-text-field
                              readonly
                              outlined
                              label="设定温度"
                              suffix="℃"
                              v-model="cloudProgram.tempSet"
                          />
                        </v-col>

                        <v-col cols="4" class="py-0">
                          <v-text-field
                              readonly
                              outlined
                              label="下发功率"
                              suffix="W"
                              v-model="cloudProgram.outputPowerRatio"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col >

            <!-- 右栏 -->
            <v-col cols="3">
              <v-card outlined style="min-height: calc(100vh - 222px)">
                <v-card-title>
                  <v-icon color="primary" class="mr-3">
                    mdi-tune
                  </v-icon>
                  <span class="font-title">参数修正</span>
                </v-card-title>

                <v-subheader
                    class="d-flex justify-space-between"
                >
									<span>
										<v-icon class="mr-2"
                    >mdi-currency-jpy </v-icon
                    >电价</span
                  >
                  <v-btn
                      text
                      color="primary"
                      @click="tariffDialog = true"
                  >
                    <v-icon left> mdi-expand-all </v-icon>
                    电价录入
                  </v-btn>
                </v-subheader>
                <v-card-text>
                  <v-row>
                    <v-col cols="6" class="py-0">
                      <v-text-field
                          label="峰值"
                          prefix="≥ ¥"
                          suffix="元/度"
                          v-model="parameters.peak"
                      />
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-text-field
                          label="谷值"
                          prefix="≤ ¥"
                          suffix="元/度"
                          v-model="parameters.valley"
                      />
                    </v-col>
                    <v-col cols="4" class="py-0">
                      <v-text-field
                          label="峰值修正"
                          suffix="℃"
                          v-model="
													parameters.adjustmentPeak
												"
                      />
                    </v-col>
                    <v-col cols="4" class="py-0">
                      <v-text-field
                          label="平值修正"
                          suffix="℃"
                          v-model="
													parameters.adjustmentFlat
												"
                      />
                    </v-col>
                    <v-col cols="4" class="py-0">
                      <v-text-field
                          label="谷值修正"
                          suffix="℃"
                          v-model="
													parameters.adjustmentValley
												"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-divider />
                <v-subheader>
                  <v-icon class="mr-2"> mdi-clock </v-icon
                  >时段
                </v-subheader>
                <v-card-text
                    style="height: calc(100vh - 533px); overflow-y: scroll"
                >
                  <TimePicker
                      v-for="(item, index) in freedom"
                      :key="index"
                      :index="index"
                      :item="item"
                      :number="freedom.length"
                      @update="receiver"
                  />

                  <v-row>
                    <v-col cols="6" class="py-0">
                      <v-btn
                          text
                          color="primary"
                          @click="addTimeSlice"
                      >
                        <v-icon left>
                          mdi-clock-plus </v-icon
                        >增加时段
                      </v-btn>
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-btn
                          text
                          color="error"
                          @click="removeTimeSlice"
                      >
                        <v-icon left>
                          mdi-clock-minus </v-icon
                        >删除时段
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-subheader>
            <v-icon class="mr-2">
              mdi-weather-partly-cloudy
            </v-icon>
            室外环境
          </v-subheader>
          <v-chip class="mr-2">
            <v-icon size="18" class="mr-2">
              mdi-thermometer
            </v-icon>
            <span v-text="outdoor.temp" />
            <small class="ml-2">℃</small>
          </v-chip>

          <v-chip class="mr-2">
            <v-icon size="18" class="mr-2">
              mdi-weather-windy-variant
            </v-icon>
            <span v-text="outdoor.windSpeed" />
            <small class="ml-2">m/s</small>
          </v-chip>

          <v-chip>
            <v-icon size="18" class="mr-2">
              mdi-sun-wireless
            </v-icon>
            <span v-text="outdoor.radiation" />
            <small class="ml-2">W/m²</small>
          </v-chip>

          <v-spacer />
          <v-btn outlined color="error" class="px-3" @click="deleteTask">删除任务</v-btn>
          <v-btn outlined color="error" class="ml-6 px-3" @click="stopTask">停止任务</v-btn>
          <v-btn outlined color="warning" class="ml-6 px-3" @click="reset"><v-icon left> mdi-refresh-circle </v-icon>重置</v-btn>
          <v-btn outlined color="primary" class="ml-6 px-3" @click="seedParameters"><v-icon left> mdi-circle-edit-outline </v-icon>计算</v-btn>
          <v-btn outlined color="success" class="ml-6 px-3" @click="openDialog"><v-icon left> mdi-check-circle </v-icon>下发</v-btn>
          <v-btn outlined color="primary" class="ml-6 px-3" @click="closeSetting"><v-icon left> mdi-close-circle </v-icon>取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirm" width="40%" min-width="600px">
      <v-card>
        <v-card-title> 确认下发 </v-card-title>
        <v-card-text>
          <span>确定将 </span>
          <span v-text="text" class="primary--text text-h6" />
          <span> 温度设定为</span>
          <span class="error--text text-h6">{{
              cloudProgram.tempSet
            }}</span>
          <span>℃?</span>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="success" class="mx-3" @click="addTask">
            <v-icon left> mdi-check-circle </v-icon>
            确定
          </v-btn>
          <v-btn
              outlined
              small
              color="primary"
              @click="confirm = false"
          >
            <v-icon left> mdi-close-circle </v-icon>
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="tariffDialog" width="50%" min-width="300px">
      <v-card>
        <v-card-title>
          <span>电价录入</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="2">
              <v-text-field
                  label="0:00~1:00"
                  v-model="tariff['0']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="1:00~2:00"
                  v-model="tariff['1']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="2:00~3:00"
                  v-model="tariff['2']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="3:00~4:00"
                  v-model="tariff['3']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="4:00~5:00"
                  v-model="tariff['4']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="5:00~6:00"
                  v-model="tariff['5']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="6:00~7:00"
                  v-model="tariff['6']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="7:00~8:00"
                  v-model="tariff['7']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="8:00~9:00"
                  v-model="tariff['8']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="9:00~10:00"
                  v-model="tariff['9']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="10:00~11:00"
                  v-model="tariff['10']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="11:00~12:00"
                  v-model="tariff['11']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="12:00~13:00"
                  v-model="tariff['12']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="13:00~14:00"
                  v-model="tariff['13']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="14:00~15:00"
                  v-model="tariff['14']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="15:00~16:00"
                  v-model="tariff['15']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="16:00~17:00"
                  v-model="tariff['16']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="17:00~18:00"
                  v-model="tariff['17']"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <v-text-field
                  label="18:00~19:00"
                  v-model="tariff['18']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="19:00~20:00"
                  v-model="tariff['19']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="20:00~21:00"
                  v-model="tariff['20']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="21:00~:22:00"
                  v-model="tariff['21']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="22:00~23:00"
                  v-model="tariff['22']"
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                  label="23:00~0:00"
                  v-model="tariff['23']"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <v-btn small color="success" class="mx-3" @click="addDict">
            <v-icon left> mdi-check-circle </v-icon>
            确定
          </v-btn>
          <v-btn
              outlined
              small
              color="primary"
              @click="tariffDialog = false"
          >
            <v-icon left> mdi-close-circle </v-icon>
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="synchronousDialog" width="50%" min-width="1024px">
      <v-card>
        <v-card-title> 获取设备 </v-card-title>
        <v-card-text>
          <v-row class="align-center">
            <v-col cols="4" offset="4" style="padding: 0">
              <v-btn color="primary" block large @click="synchronous">
                <v-icon left>mdi-cloud-download</v-icon>
                同步涂鸦平台设备
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" style="margin: 0; padding: 0 20px">
              <v-select
                  clearable
                  v-model="categoryEquipment"
                  :items="categoryEquipmentItems"
                  item-text="text"
                  item-value="value"
                  label="设备类型"
              >
              </v-select>
            </v-col>
            <v-col cols="4" style="margin: 0; padding: 0 20px">
              <v-select
                  clearable
                  v-model="statusEquipment"
                  :items="statusEquipmentItems"
                  item-text="text"
                  item-value="value"
                  label="启用/停用"
              >
              </v-select>
            </v-col>
            <v-col cols="4" style="margin: 0; padding: 0 20px">
              <v-select
                  clearable
                  v-model="assetItem"
                  :items="assetItems"
                  item-text="name"
                  item-value="id"
                  label="房间"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-data-table
              :headers="headers"
              :items="desserts"
              :loading="loadingEquipment"
              :page.sync="page"
              :items-per-page.sync="itemsPerPage"
              :server-items-length="totalDesserts"
              class="elevation-0"
          >
            <template v-slot:item.sort="props">
              <v-edit-dialog :return-value.sync="props.item.sort" @save="updateItme(props.item)">
                <div>{{ props.item.sort }}</div>
                <template v-slot:input>
                  <v-text-field v-model="props.item.sort" label="排序" counter autofocus></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  createEquipment,
  createOneEquipment,
  listEquipment,
  QuerylistEquipment,
  retrieveAssetEquipment,
  updateEquipment,
} from "@/api/tuya/equipment";
import { EquipmentStatus } from "@/api/tuya/tuya";
import TimePicker from "@/components/TimePicker";
import {createTask, deleteSchedulerTask, retrieveWeather, stopSchedulerTask} from "@/api/tuya/task";
import moment from "moment";
import { createAsset, listAsset } from "@/api/tuya/asset";
import {createDict, createParameters, listDict} from "@/api/tuya/dict";
// import Top from "@/views/Settings/components/Top";

export default {
  name: "Settings",
  components: { TimePicker },
  props: ["Status"],
  data() {
    return {
      loading: false,
      items: [],
      search: null,
      select: null,
      timer: null,

      confirm: false,
      text: null,
      model: null,
      equipmentStatusList: [],
      SumNowCurrent: [],
      capacityList: [],
      SumEffectiveCurrent: [],
      oldSumNowCurrent: [],
      userMaxCurrent: 0,
      electricityMeterCurrent: 0,
      userUsableCurrent: 0,
      temperatureDifferences: [],
      numTemp: 0,

      tariffDialog: false,
      tariff: {
        0: null,
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
        7: null,
        8: null,
        9: null,
        10: null,
        11: null,
        12: null,
        13: null,
        14: null,
        15: null,
        16: null,
        17: null,
        18: null,
        19: null,
        20: null,
        21: null,
        22: null,
        23: null,
      },

      // 设备表格
      synchronousDialog: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "名称", value: "name" },
        { text: "类型", value: "category" },
        { text: "所属房间", value: "asset.name" },
        { text: "启停用", value: "isdata" },
        { text: "排序", value: "sort" },
      ],
      desserts: [],
      searchEquipment: null,
      page: 1,
      itemsPerPage: 10,
      totalDesserts: 0,
      loadingEquipment: false,
      categoryEquipmentItems: [
        { text: "人体传感器", value: "pir" },
        { text: "电暖器", value: "qn" },
        { text: "温控器", value: "wk" },
        { text: "网关", value: "wg2" },
        { text: "智能电表", value: "zndb" },
      ],
      categoryEquipment: null,
      statusEquipmentItems: [
        { text: "启用", value: true },
        { text: "停用", value: false },
      ],
      statusEquipment: null,
      statusChoose: null,
      assetItems: [],
      assetItem: null,
      editDialog: false,
      equipment: null,
      equipmentId: null,

      time: null,
      freedom: [
        {
          time_1: "00:00",
          time_2: "10:00",
          correction: 0,
          index: 1,
        },
        {
          time_1: "10:01",
          time_2: "15:00",
          correction: -0.5,
          index: 2,
        },
        {
          time_1: "15:01",
          time_2: "23:59",
          correction: 0,
          index: 3,
        },
      ],

      communityList: [],
      community: null,

      floorList: [],
      floor: null,

      unitList: [],
      unit: null,

      roomList: [],
      room: null,

      temp: 8,

      interval: 30, // 数据读取间隔
      timer_2: null,
      currentMax: null,

      timer_3: null,

      pir: {
        pir: null,
        batteryState: null,
        temperAlarm: null,
        tempCurrent: null,
        pirTime: null,
      },

      qn: {
        switch: null, // 开关
        tempSet: null, // 目标温度
        tempCurrent: null, // 当前温度
        mode: null, // 工作模式
        lock: null, // 童锁
        workState: null, // 加热状态
        workPower: null, // 当前功率
        fault: null, // 故障告警
        weekProgramTemp: null, // 周程序
        deviceNum: null, // 设备号
        windowCheck: null, // 开窗检测
        outP: null, //  输出功率比
        capacity: null, // 额定功率
        powerConsumption: null, // 耗电量
        ysM: null, // 控制模式
        frost: null, // 防霜冻功能
        ysB: null, // 控制参数1
        ysC: null, // 控制参数2
        ysF: null, // 控制参数5
        operationLog: null, //操作日志
        currentMax: null, // 电表最大电流
        dqdl: null, // 电表当前电流
        pirState0: null, // 人体传感状态
        p1: null,
        p2: null,
        p3: null,
        p4: null,
        p5: null,
        p6: null,
        testDp: null, // 测试db
        update_f: null,
        power_effective: null,
      },
      pattern: "M1",

      zndb: {
        id: null,
        switch: null,
        meter_id: null,
        current_max: null,
        dqdl: null,
      },

      cloudProgram: {
        status: true,
        time: null,
        tempSet: null,
        outputPowerRatio: null,
      },

      outdoor: {
        temp: null,
        windSpeed: null,
        radiation: null,
      },

      parameters: {
        direction1: 90,
        direction2: 270,
        adjustmentDirection: 0,
        adjustmentRoomDirection: 0.5,
        solarRadiation: 350,
        adjustmentSolarRadiation_1: -1,
        adjustmentSolarRadiation_2: 0,
        windSpeed: 3,
        adjustmentWindSpeed_1: 0.5,
        adjustmentWindSpeed_2: 0,
        peak: 1.0,
        adjustmentPeak: 0,
        valley: 0.5,
        adjustmentValley: 0,
        adjustmentFlat: 0,
      },

      refresh: true,
      commandDict: {},
    };
  },

  watch: {

    search(val) {
      if (val !== "" && val !== null && val.length <= 19) {
        val && val !== this.select && this.querySelections(val);
      }
    },

    synchronousDialog(val) {
      if (val) {
        this.getDataFromApi();
      } else {
        this.desserts = [];
      }
    },

    statusEquipment() {
      this.getDataFromApi();
    },

    categoryEquipment() {
      this.getDataFromApi();
    },

    assetItem() {
      this.getDataFromApi();
    },

    page: {
      handler() {
        this.getDataFromApi();
      },
    },

    itemsPerPage: {
      handler() {
        this.getDataFromApi();
      },
    },
  },

  created() {
    this.$store.dispatch("initWebsocket");
    this.$store.getters.callbackInfo.onmessage = this.WebSocketOnMessage;
    this.getAssetList("community", "null", 1);
    this.getDict();
    listAsset({ level: 4 }).then((res) => {
      this.assetItems = res;
    });
  },

  mounted() {
    const self = this;
    function fn() {
      retrieveWeather().then((res) => {
        self.outdoor.temp = Number(res.data["weather"]["tmp"]);
        self.outdoor.radiation = Number(res.data["weather"]["fzd"]);
        self.outdoor.windSpeed = Number(res.data["weather"]["winds"]);
      });
      return fn; // 函数中返回自身
    }
    setInterval(fn(), 1000 * 1800); // 定时器中，函数先执行一次
  },

  beforeDestroy() {
    this.oldSumNowCurrent = [];
    clearInterval(this.timer_2);
    this.timer_2 = null;
  },

  methods: {
    WebSocketOnMessage(event) {
      const data = JSON.parse(event.data);
      if (data.type === "error") {
        this.$message.error(data.data);
      } else if (data.type === "response") {
        // this.$notify({
        //   title: "回调信息",
        //   message: data.data,
        //   type: "success",
        //   duration: 2000,
        //   offset: 100,
        // });
      } else if (data.type === "request") {
        // this.$notify({
        //   title: "下发指令",
        //   message: data.data,
        //   type: "success",
        //   duration: 2000,
        //   offset: 100,
        // });
      } else if (data.type === "result") {
        console.log(data.data)
        if ( this.select !== null) {
          const result = data.data[this.select.id]
          if (result !== undefined) {
            this.cloudProgram.outputPowerRatio = result['power'];
            this.cloudProgram.tempSet = (result['temp'] / 10)?.toFixed(1);
          }
        }
      }
    },

    closeSetting() {
      this.clearChoose("all");
      this.oldSumNowCurrent = [];
      this.$emit("transferStatus", false);
    },

    getAssetList(category, object, level) {
      if (object === null) {
        switch (category) {
          case "floor":
            this.floor = null;
            this.floorList = [];
            this.unit = null;
            this.unitList = [];
            this.room = null;
            this.roomList = [];
            this.select = null;
            break;
          case "unit":
            this.unit = null;
            this.unitList = [];
            this.room = null;
            this.roomList = [];
            this.select = null;
            break;
          case "room":
            this.room = null;
            this.roomList = [];
            this.select = null;
            break;
        }
        return;
      }
      const params = {};
      if (object !== "null") {
        params["parent"] = object.id;
      }
      params["level"] = level;
      listAsset(params).then((res) => {
        switch (category) {
          case "community":
            this.communityList = res;
            this.floor = null;
            this.floorList = [];
            this.unit = null;
            this.unitList = [];
            this.room = null;
            this.roomList = [];
            break;
          case "floor":
            this.floorList = res;
            this.unit = null;
            this.unitList = [];
            this.room = null;
            this.roomList = [];
            break;
          case "unit":
            this.unitList = res;
            this.room = null;
            this.roomList = [];
            break;
          case "room":
            this.roomList = res;
        }
      });
    },

    getEquipmentList(room) {
      this.items = [];
      if (room !== null) {
        this.loading = true;
        listEquipment({ asset: room.id }).then((res) => {
          res.forEach((item) => {
            item.text = `${item.id} | ${item.category} | ${item.name}`;
            this.items.push(item);
          });
        });
      }
      this.select = null;
      this.loading = false;
    },

    querySelections(v) {
      this.items = [];
      this.loading = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        listEquipment({ search: v }).then((res) => {
          res.forEach((item) => {
            item.text = `${item.id} | ${item.category} | ${item.name}`;
            this.items.push(item);
          });
        });
        this.loading = false;
      }, 500);
    },

    // 打开确认选项卡
    openDialog() {
      if (this.select !== null && this.select.category === "qn") {
        if (
            this.cloudProgram.tempSet === "" ||
            this.cloudProgram.tempSet === null
        ) {
          this.$message({
            message: "未完成计算",
            type: "warning",
            duration: 3 * 1000,
          });
        } else {
          this.confirm = true;
          this.text = `${this.select.name} (ID: ${this.select.id})`;
        }
      } else {
        this.$message({
          message: "传感器选择错误",
          type: "warning",
          duration: 3 * 1000,
        });
      }
    },

    deleteTask() {
      if (this.zndb.id === null) {
        this.$message({
          message: "未选择任务",
          type: "warning",
          duration: 3 * 1000,
        });
        return;
      }
      deleteSchedulerTask({zndb: this.zndb.id}).then(() => {
        this.$message({
          message: "删除成功",
          type: "success",
          duration: 3 * 1000,
        });
      });
    },

    stopTask() {
      if (this.zndb.id === null) {
        this.$message({
          message: "未选择任务",
          type: "warning",
          duration: 3 * 1000,
        });
        return;
      }
      stopSchedulerTask({zndb: this.zndb.id}).then(() => {
        this.$message({
          message: "停止成功",
          type: "success",
          duration: 3 * 1000,
        });
      });
    },

    reset() {
      this.clearChoose("all");
      Object.keys(this.parameters).forEach((item) => {
        this.parameters[item] = null;
      });
      this.freedom = [
        {
          time_1: "00:00",
          time_2: "23:59",
          correction: null,
          index: 1,
        },
      ];
    },

    async getEquipmentStatus(select) {
      if (select !== null) {
        clearInterval(this.timer_2);
        this.clearChoose("object");
        await retrieveAssetEquipment(select.id).then((res) => {
          if (res["asset"] !== null) {
            this.room = res["asset"];
            this.roomList.push(res["asset"]);
            this.unit = res["asset"]["parent"];
            this.unitList.push(res["asset"]["parent"]);
            this.floor = res["asset"]["parent"]["parent"];
            this.floorList.push(res["asset"]["parent"]["parent"]);
            this.community =
                res["asset"]["parent"]["parent"]["parent"];
          } else {
            this.room = null;
            this.roomList = [];
            this.unit = null;
            this.unitList = [];
            this.floor = null;
            this.floorList = [];
            this.community = null;
          }
        });
        await listEquipment({asset: this.room.id, category: "zndb"}).then( (res)=> {
          if (res.length === 0){
            this.$message({
              message: "此房间无智能电表",
              type: "warning"
            })
            return
          }
          if (res.length > 1){
            this.$message({
              message: "此房间有多块智能电表",
              type: "warning"
            })
            return
          }
          this.zndb = res[0]
          this.getStatus(select, this.zndb)
          this.timer_2 = setInterval(
              this.getStatus,
              1000 * this.interval,
              select, this.zndb,
          );
        })
      } else {
        this.clearChoose("all");
      }
    },

    async getStatus(select, zndb){
      await EquipmentStatus({ id: select.id }).then((res) => {
        if (res["result"].length !== 0) {
          switch (select.category) {
            case "pir":
              this.pir.pir = null;
              res["result"].forEach((item) => {
                switch (item.code) {
                  case "pir":
                    this.pir.pir = item.value === "pir";
                    break;
                  case "battery_state":
                    this.pir.batteryState = item.value;
                    break;
                  case "temper_alarm":
                    this.pir.temperAlarm = item.value;
                    break;
                  case "temp_current":
                    item.value = (Number(item.value) / 10).toFixed(1);
                    this.pir.tempCurrent = item.value;
                    break;
                  case "pir_time":
                    this.pir.pirTime = item.value.split("s")[0];
                    break;
                }
              });
              break;
            case "qn":
              this.qn.time = moment(res["t"]).format("YYYY-MM-DD HH:mm:ss");
              res["result"].forEach((item) => {
                switch (item.code) {
                  case "switch": // 开关
                    this.qn.switch = item.value;
                    break;
                  case "temp_set": // 目标温度
                    this.qn.tempSet = (Number(item.value) / 10).toFixed(1);
                    break;
                  case "temp_current": // 当前温度
                    this.qn.tempCurrent = (Number(item.value) / 10).toFixed(1);
                    break;
                  case "mode": // 工作模式
                    this.qn.mode = item.value;
                    break;
                  case "lock": // 童锁
                    this.qn.lock = item.value;
                    break;
                  case "work_state": // 加热状态
                    this.qn.workState = item.value !== "stopping";
                    break;
                  case "work_power": // 当前功率
                    this.qn.workPower = Number(item.value);
                    break;
                  case "fault": // 故障告警
                    this.qn.fault = item.value;
                    break;
                  case "week_program_temp": // 周程序
                    this.qn.weekProgramTemp = item.value;
                    break;
                  case "device_num": // 设备号
                    this.qn.deviceNum = item.value;
                    break;
                  case "window_check": // 开窗检测
                    this.qn.windowCheck = item.value;
                    break;
                  case "out_p": // 输出功率比
                    this.qn.outP = item.value;
                    break;
                  case "capacity": // 额定功率
                    this.qn.capacity = Number(item.value).toFixed(1);
                    break;
                  case "power_consumption": // 耗电量
                    this.qn.powerConsumption = (Number(item.value) / 10).toFixed(1);
                    break;
                  case "ys_m": // 控制模式
                    this.qn.ysM = item.value;
                    break;
                  case "frost": // 防霜冻功能
                    this.qn.frost = item.value;
                    break;
                  case "ys_b": // 控制参数1
                    this.qn.ysB = item.value;
                    break;
                  case "ys_c": // 控制参数2
                    this.qn.ysC = item.value;
                    break;
                  case "ys_f": // 控制参数5
                    this.qn.ysF = item.value;
                    break;
                  case "operation_log": // 操作日志
                    this.qn.operationLog = item.value;
                    break;
                  case "current_max": // 电表最大电流
                    this.qn.currentMax = (Number(item.value) / 10).toFixed(1);
                    break;
                  case "dqdl": // 电表当前电流
                    this.qn.dqdl = (Number(item.value) / 10).toFixed(1);
                    break;
                  case "pir_state0": // 人体传感状态
                    this.qn.pirState0 = item.value === "pir";
                    break;
                  case "p1":
                    this.qn.p1 = item.value;
                    break;
                  case "p2":
                    this.qn.p2 = item.value;
                    break;
                  case "p3":
                    this.qn.p3 = item.value;
                    break;
                  case "p4":
                    this.qn.p4 = item.value;
                    break;
                  case "p5":
                    this.qn.p5 = item.value;
                    break;
                  case "p6":
                    this.qn.p6 = item.value;
                    break;
                  case "test_dp": // 测试db
                    this.qn.testDp = item.value;
                    break;
                  case "power_effective": // 需求功率
                    this.qn.power_effective = item.value;
                    break;
                }
              });
              break;
            case "zndb":
              res["result"].forEach((item) => {
                switch (item.code) {
                  case "switch":
                    this.zndb.switch =
                        item.value;
                    break;
                  case "meter_id":
                    this.zndb.meter_id =
                        item.value;
                    break;
                  case "current_max":
                    this.zndb.current_max = (item.value / 10).toFixed(1);
                    break;
                  case "dqdl":
                    this.zndb.dqdl = ((item.value / 10) * 220).toFixed(1);
                    break;
                }
              });
              break;
          }
        }
      });
      await EquipmentStatus({ id: zndb.id }).then((res) => {
        res["result"].forEach((item) => {
          switch (item.code) {
            case "switch":
              this.zndb.switch = item.value;
              break;
            case "meter_id":
              this.zndb.meter_id = item.value;
              break;
            case "current_max":
              this.zndb.current_max = (item.value / 10).toFixed(1);
              this.currentMax = (item.value / 10 * 220).toFixed(0);
              break;
            case "dqdl":
              this.zndb.dqdl = ((item.value / 10) * 220).toFixed(1);
              break;
          }
        })
      });
      await this.fresh()
    },

    fresh(){
      this.refresh = false;
      this.$nextTick( ()=>{
        this.refresh = true
      })
    },

    clearChoose(choose) {

      clearInterval(this.timer_2);
      this.timer_2 = null;

      clearInterval(this.timer_3);
      this.timer_3 = null;

      Object.keys(this.qn).forEach((item) => {
        this.qn[item] = null;
      });

      Object.keys(this.pir).forEach((item) => {
        this.pir[item] = null;
      });
      this.cloudProgram.tempSet = null;
      this.cloudProgram.outputPowerRatio = null;

      switch (choose) {
        case "all":
          this.select = null;
          this.room = null;
          this.floor = null;
          this.unit = null;
          this.community = null;
          this.roomList = [];
          this.floorList = [];
          this.unitList = [];
          break;
        case "object":
          break;
      }
    },

    addTimeSlice() {
      if (this.freedom.length === 0) {
        this.freedom = [
          {
            time_1: "00:00",
            time_2: "23:59",
            correction: null,
            index: 1,
          },
        ];
      } else {
        this.freedom.push({
          time_1: null,
          time_2: "23:59",
          correction: null,
          index: this.freedom.length + 1,
        });
      }
    },

    removeTimeSlice() {
      this.freedom.pop();
      if (this.freedom.length === 1) {
        this.freedom = [
          {
            time_1: "00:00",
            time_2: "23:59",
            correction: null,
            index: 1,
          },
        ];
      }
    },

    receiver(val) {
      this.freedom[val.index - 1] = val;
    },

    // 保存电价信息到数据库
    addDict() {
      this.tariffDialog = false;
      createDict({ key: "price", json: this.tariff }).then();
    },

    getDict() {
      listDict({ key: "price" }).then((res) => {
        this.tariff = res[0]["json"];
        this.dict = res[0]["id"];
      });
    },

    // 下发任务
    async addTask() {
      // 计算 下发
      const data = {
        zndb: this.zndb,
        select: this.select,
        interval: this.interval,
        pattern: this.pattern,
      }
      createTask(data).then( (res)=>{
        if (res["detail"] === "任务下发成功") {
          this.$message.success("下发成功");
        } else {
          this.$message.error("下发失败");
        }
        this.confirm = false;
      })
    },

    // 与涂鸦平台同步数据
    async synchronous() {
      try {
        await createEquipment().then(() => {
          this.$message({
            message: "同步成功",
            type: "success",
            duration: 3 * 1000,
          });
        });
      } finally {
        await createAsset().then(() => {});
        await this.getDataFromApi();
      }
    },

    // 手动添加设备
    addEquipment() {
      createOneEquipment({ id: this.equipmentId }).then(() => {
        this.$message({
          message: "添加成功",
          type: "success",
          duration: 3 * 1000,
        });
        this.getDataFromApi();
      });
    },

    getDataFromApi() {
      this.loadingEquipment = true;
      let params = {
        page: this.page,
        pagesize: this.pageSize(this.itemsPerPage),
      };
      if (this.statusEquipment !== null) {
        params["isdata"] = this.statusEquipment;
      }
      if (this.categoryEquipment !== null) {
        params["category"] = this.categoryEquipment;
      }
      if (this.assetItem !== null) {
        params["asset"] = this.assetItem;
      }
      QuerylistEquipment(params).then((res) => {
        this.desserts = [];
        this.totalDesserts = res["count"];
        this.desserts = res["results"];
      });
      this.loadingEquipment = false;
    },

    pageSize(pagesize) {
      if (pagesize === -1) {
        return 1000;
      } else return pagesize;
    },

    clickRow(data) {
      this.clearChoose("object");
      data.text = `${data.id} | ${data.category} | ${data.name}`;
      this.items.push(data);
      this.select = data;
      this.getEquipmentStatus(data);
      this.synchronousDialog = false;
    },

    updateItme(item) {
      this.equipment = item;
      updateEquipment(this.equipment.id, {
        sort: item.sort,
      }).then(() => {
        this.$message({
          message: "更新成功",
          type: "success",
          duration: 3 * 1000,
        });
        this.getDataFromApi();
        this.editDialog = false;
      });
    },

    // 发送参数
    seedParameters(){
      if (this.zndb.id !== null){
        this.parameters['freedom'] = this.freedom
        this.parameters['temp'] = this.temp
        const data = {
          "key": this.zndb.id,
          "parameters": this.parameters,
          "text": this.select.id
        }
        createParameters(data).then( res => {
          console.log(res)
          this.cloudProgram.tempSet = res['temp_set']
          this.$message({
            message: "发送成功",
            type: "success",
            duration: 3 * 1000,
          });
        });
      } else {
        this.$message({
          message: "请先选择设备",
          type: "warning",
          duration: 3 * 1000,
        });
      }
    }
  },
};
</script>

<style scoped>
.panel-title {
  letter-spacing: 1rem;
  font-size: 1.8rem;
  padding-left: 1rem;
}
/*.section-title {*/
/*  font-family: HuXiaoBoNanShenTi, sans-serif;*/
/*  letter-spacing: 0.2rem;*/
/*  font-size: 1.2rem;*/
/*}*/
</style>
