import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout/Index'
Vue.use(VueRouter)

const routes = [
  {
    path: '/index',
    component: Layout,
    children: [
      {
        name: 'Dashboard',
        path: '/dashboard',
        component: () => import('../views/Dashboard.vue'),
      },
    ],
  },
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '*',
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router;
