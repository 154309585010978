import axios from "axios"
import errorCode from "./errorCode";
import Vue from "vue";
import {getToken} from "@/utils/auth";

// 配置拦截器
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BACKEND_URL,
  // 超时
  timeout: 100000
})

// 请求拦截器
service.interceptors.request.use(
  config =>{
    // 是否需要设置token
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
        config.headers['Authorization'] = 'Bearer ' + getToken()  // 让每个请求携带自定义token
    }
    return config
  },
  error => {
    console.log(error)
  },
)

// 响应拦截器
service.interceptors.response.use(
  // 拦截器响应成功返回
  res => {
    return res.data
  },
  // 响应失败返回
  error => {
    let { message } = error;
    if (error.response){
      const code = error.response.status
      message = error.response.data || errorCode[code] || errorCode['default']
    }
    else if (message === 'Network Error') {
      message = '后端接口连接异常';
    }
    else if (message.includes('timeout')) {
      message = '系统接口请求超时';
    }
    Vue.prototype.$message({
      message: message,
      type: 'error',
      duration: 3 * 1000
    })
    return Promise.reject(error)
  },
)

export default service
