const getters = {
    barColor: state => state.system.barColor,
    barImage: state => state.system.barImage,
    drawer: state => state.system.drawer,
    showSettingsDialog: state => state.system.showSettingsDialog,
    callbackInfo: state => state.system.callbackInfo,
    token: state => state.user.token,
    username: state => state.user.username,
    user_id: state => state.user.user_id,
    groups: state => state.user.groups,
}

export default getters
