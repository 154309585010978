import Vue from 'vue'
import Vuetify from 'vuetify'
import colors from 'vuetify/lib/util/colors'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
// import '@/sass/overrides.sass'

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
}

const lightTheme = {
  primary: colors.lightBlue.base,
  secondary: colors.grey.lighten2,
  accent: colors.deepOrange.base,
  error: colors.red.accent2,
  info: colors.blue.base,
  success: colors.green.base,
  warning: colors.amber.darken2,

  // 当以上标准的颜色变量不能满足需求时，程序员也可以自定义颜色变量。请注意两种主题要保持变量名一致。
  bg: "#eeeeee",
  // cardBg: ""
}

const darkTheme = {
  primary: colors.lightBlue.base,
  secondary: colors.grey.darken4,
  accent: colors.deepOrange.darken3,
  error: colors.red.accent4,
  info: colors.blue.darken3,
  success: colors.green.darken3,
  warning: colors.amber.darken3,

  // 自定义颜色变量。请注意两种主题要保持变量名一致。
  bg: "#000000",
  // cardBg: ""
}

const vuetify = new Vuetify({
  opts,
  theme: {
    themes: {
      dark: darkTheme,
      light: lightTheme,
    },
  },
})

export default vuetify