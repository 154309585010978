<!--@Time : 2022/5/27 16:09-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
    <div id="temperature_container"></div>
</template>

<script>
import {Chart} from "@antv/g2";
import {listTemperature} from "@/api/tuya/statistics";

export default {
  name: "TemperatureLineChart",
  data(){
    return {
      chart: null,
      data: [],
    }
  },
  props: ["cycle"],
  created() {
  },
  mounted() {
    this.getListTemperature(this.cycle)
    this.initChart()
  },
  watch: {
    "cycle": {
      handler (newValue) {
        this.getListTemperature(newValue)
      }
    }
  },
  methods:{
    initChart(){
      this.chart = new Chart({
        container: 'temperature_container',
        autoFit: true,
      });
      this.chart.data(this.data);
      this.chart.scale({
        day: ()=> {
          if (this.cycle === 1){
            return {
              range: [0, 1],
              type: 'time',
              mask: "YYYY-MM-DD HH:mm"
            }
          } else {
            return {
              range: [0, 1],
              type: 'time',
              mask: "YYYY-MM-DD"
            }
          }
        },
        temperature: {
          nice: true,
          min: 0,
        },
      });


      this.chart.tooltip({
        showCrosshairs: true,
        shared: true,
      });

      this.chart.axis('temperature', {
        label: {},
      });

      this.chart.legend({
				position: "bottom",
			});

      this.chart
          .line()
          .position('day*temperature')
          .color('type', (type)=>{
            if (type === '设定温度'){
              return "#4CAF50";
            }else {
              return "#03A9F4";
            }
          })
          .shape('smooth');

      this.chart.render();
    },
    getListTemperature(cycle){
      listTemperature({cycle: cycle}).then( (res)=> {
        this.data = res
        this.chart.changeData(this.data)
      })
    }
  }
}
</script>

<style scoped>

</style>