<template>
	<v-app id="app">
		<router-view />
	</v-app>
</template>

<script>
export default {
	name: "App",
};
</script>

<style>
@font-face {
	font-family: HYLingXin;
	src: url(https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/fonts/HYLingXin.ttf);
}
@font-face {
	font-family: LcdD;
	src: url(https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/fonts/LcdD.ttf);
}
@font-face {
	font-family: HuXiaoBoNanShenTi;
	src: url(https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/fonts/HuXiaoBoNanShenTi-2.otf);
}
.font-title {
	font-family: HuXiaoBoNanShenTi, sans-serif !important;
}
.font-digit {
	font-family: LcdD, sans-serif !important;
}
</style>