<!--@Time : 2022/5/26 15:37-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<div>
		<v-app-bar app elevate-on-scroll>

			<div
				class="site-title font-title text-center"
				style="position: absolute; left: 25%; width: 50%;	font-size: 30px;">
				供暖电力化数据看板
			</div>

			<v-spacer></v-spacer>

			<v-icon class="mr-2">mdi-theme-light-dark</v-icon>
			<v-switch
				hide-details
				label=""
				v-model="$vuetify.theme.dark"
			></v-switch>

			<v-btn text icon @click="setStatus(true)">
				<v-icon>mdi-cog</v-icon>
			</v-btn>

			<v-btn text rounded @click="Logout">
				<v-icon>mdi-logout</v-icon> 退出
			</v-btn>

		</v-app-bar>
		<Settings :Status="status"  @transferStatus="setStatus"></Settings>
	</div>
</template>

<script>
import Settings from "../../views/Settings/Settings";
export default {
	name: "AppBar",
	components: { Settings },
	data() {
		return {
			status: false,
		};
	},
	methods: {
    WebsocketClose(e) {
			console.log("断开连接", e);
		},

    setStatus(data){
      this.status = data
    },

		showSettingsDialog() {
      this.status = true
		},
		Logout() {
      // this.$store.getters.callbackInfo.onclose = this.WebsocketClose
      this.$store.getters.callbackInfo.close()
      this.$notify.closeAll()
			this.$store.dispatch("LogOut");
		},
	},
};
</script>

<style scoped>
.site-title {
	letter-spacing: 1rem;
	font-size: 2rem;
	font-weight: 500;
	padding-left: 1rem;
}
</style>