import request from "../../utils/request";


// 查询列表
export async function listDict(params) {
    return request({
    url: '/api/tuya/dicts/',
    method: 'get',
    params: params,
  })
}


// 查询单个
export async function retrieveDict(Id) {
  return request({
    url: `/api/tuya/dicts/${Id}/`,
    method: 'get',
  })
}


// 创建
export async function createDict(data) {
  return request({
    url: '/api/tuya/dicts/',
    method: 'post',
    data: data
  })
}

// 删除dict
export async function deleteDict(Id) {
  return request({
    url: `/api/tuya/dicts/${Id}`,
    method: 'delete',
  })
}

export async function updateDict(Id) {
  return request({
    url: `/api/tuya/dicts/${Id}`,
    method: 'patch',
  })
}

// 发送参数
export async function createParameters(data) {
  return request({
    url: '/api/tuya/dicts/parameters/',
    method: 'post',
    data: data
  })
}
