<!--@Time : 2022/5/26 15:38-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
  <v-footer
    app
    class="justify-center py-0"
  >
    <div class="text-overline">
      <!--      版权所有 &copy; 2022 中国建筑科学研究院有限公司-->
    </div>
  </v-footer>
</template>
<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>