import Vuex from "vuex";
import Vue from "vue";
import system from "./modules/system";
import user from "./modules/user";
import getters from "./getters";

Vue.use(Vuex);


const store = new Vuex.Store({
  modules: {
    system,
    user,
  },
  getters,
});

export default store;