<template>
	<div id="habit_container"></div>
</template>

<script>
import {Chart, registerShape} from "@antv/g2";

export default {
	name: "HabitChart",
	data() {
		return {
			chart: null,
			data_1: [
				{ type: "模式一", value: 0.27, color: "#4CAF50" },
				{ type: "模式二", value: 0.57, color: "#FFA000" },
				{ type: "模式三", value: 0.16, color: "#03A9F4" },
			],
		};
	},
	mounted() {
		this.initChart();
	},
	methods: {
		initChart() {
			registerShape("point", "pointer", {
				draw(cfg, group) {
					const point = cfg.points[0];
					const center = this.parsePoint({ x: 0, y: 0 });
					const target = this.parsePoint({ x: point.x, y: 0.9 });
					const dir_vec = {
						x: center.x - target.x,
						y: center.y - target.y,
					};
					// normalize
					const length = Math.sqrt(
						dir_vec.x * dir_vec.x + dir_vec.y * dir_vec.y
					);
					dir_vec.x *= 1 / length;
					dir_vec.y *= 1 / length;
					// rotate dir_vector by -90 and scale
					const angle1 = -Math.PI / 2;
					const x_1 =
						Math.cos(angle1) * dir_vec.x -
						Math.sin(angle1) * dir_vec.y;
					const y_1 =
						Math.sin(angle1) * dir_vec.x +
						Math.cos(angle1) * dir_vec.y;
					// rotate dir_vector by 90 and scale
					const angle2 = Math.PI / 2;
					const x_2 =
						Math.cos(angle2) * dir_vec.x -
						Math.sin(angle2) * dir_vec.y;
					const y_2 =
						Math.sin(angle2) * dir_vec.x +
						Math.cos(angle2) * dir_vec.y;
					// polygon vertex
					const path = [
						["M", target.x + x_1, target.y + y_1],
						["L", center.x + x_1 * 3, center.y + y_1 * 3],
						["L", center.x + x_2 * 3, center.y + y_2 * 3],
						["L", target.x + x_2, target.y + y_2],
						["Z"],
					];
          return group.addShape("path", {
            attrs: {
              path,
              fill: cfg.color,
            },
          });
				},
			});

			this.chart = new Chart({
				container: "habit_container",
				autoFit: true,
			});

			this.chart.data(this.data_1);
			this.chart.coordinate("polar", {
				startAngle: (-10 / 8) * Math.PI,
				endAngle: (2 / 8) * Math.PI,
				radius: 0.75,
			});
			this.chart.scale("value", {
				min: 0,
				max: 1,
				tickInterval: 1,
			});

			this.chart.axis(false);
			this.chart.facet("rect", {
				fields: ["type"],
				showTitle: false,
				eachView: function eachView(view, facet) {
					const data = facet.data[0];
					// 指针
					view.point()
						.position("value*1")
						.shape("pointer")
						.color("#FF5252")
						.animate({
							appear: {
								animation: "fade-in",
							},
						});
					// 仪表盘背景
					view.annotation().arc({
						top: false,
						start: [0, 1],
						end: [1, 1],
						style: {
							stroke: "#BDBDBD",
							lineWidth: 10,
						},
					});
					// 仪表盘前景
					view.annotation().arc({
						start: [0, 1],
						end: [data.value, 1],
						style: {
							stroke: data.color,
							lineWidth: 14,
						},
					});
					// 仪表盘信息
					const percent = parseInt(data.value * 100, 10);

					view.annotation().text({
						position: ["50%", "70%"],
						content: data.type,
						style: {
							fontSize: 14,
							fill: "#8c8c8c",
							fontWeight: 300,
							textAlign: "center",
						},
						offsetX: 0,
					});
					view.annotation().text({
						position: ["50%", "80%"],
						content: `${percent}%`,
						style: {
							fontSize: 28,
							fill: data.color,
							fontWeight: 600,
							textAlign: "center",
						},
						offsetX: 0,
						offsetY: 10,
					});
				},
			});

			this.chart.render();
		},
	},
};
</script>

<style scoped>
</style>