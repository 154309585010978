<template>
	<v-row>
		<v-col cols="3">
			<v-row>
				<v-col cols="12">
					<v-card flat color="cardBg" class="data-card">
						<v-card-title>
							<v-icon color="primary" class="mr-3">
								mdi-thermometer
							</v-icon>
							<span class="font-title">温度</span>
							<div style="position: absolute; right: 16px">
								<v-btn text color="primary" @click="cycle = 7">
									近7天
								</v-btn>
								<v-btn text color="primary" @click="cycle = 1">
									24小时
								</v-btn>
							</div>
						</v-card-title>
						<v-card-text>
							<TemperatureLineChart style="height: calc(((100vh - 180px) / 3) - 64px)" :cycle="cycle"/>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12">
					<v-card flat color="cardBg" class="data-card">
						<v-card-title>
							<v-icon color="primary" class="mr-3">
								mdi-lightning-bolt-circle
							</v-icon>
							<span class="font-title">功率</span>
							<div style="position: absolute; right: 16px">
								<v-btn
									text
									color="primary"
									@click="power_cycle = 7"
								>
									近7天
								</v-btn>
								<v-btn
									text
									color="primary"
									@click="power_cycle = 1"
								>
									24小时
								</v-btn>
							</div>
						</v-card-title>
						<v-card-text>
							<PowerLineChart
								style="
									height: calc(((100vh - 180px) / 3) - 64px);
								"
								:power_cycle="power_cycle"
							/>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12">
					<v-card flat color="cardBg" class="data-card">
						<v-card-title>
							<v-icon color="primary" class="mr-3">
								mdi-flash
							</v-icon>
							<span class="font-title">耗电量</span>
							<div style="position: absolute; right: 16px">
								<v-btn
									text
									color="primary"
									@click="double_cycle = 7"
								>
									近7天
								</v-btn>
								<v-btn
									text
									color="primary"
									@click="double_cycle = 1"
								>
									24小时
								</v-btn>
							</div>
						</v-card-title>
						<v-card-text>
							<DoubleAxesChart
								:double_cycle="double_cycle"
								class="chart"
							/>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-col>

		<v-col cols="6">
			<v-card flat color="cardBg">
				<v-card-title>
					<v-row>
						<v-col cols="2" class="pb-0">
							<v-select
								v-model="community"
								:items="communityList"
								item-text="name"
								item-value="id"
								label="小区"
								clearable
								return-object
								@change="getAssetList('floor', community, 2)"
							/>
						</v-col>
						<v-col cols="2" class="pb-0">
							<v-select
								v-model="floor"
								:items="floorList"
								item-text="name"
								item-value="id"
								label="楼栋"
								clearable
								return-object
								@change="getAssetList('unit', floor, 3)"
							/>
						</v-col>
						<v-col cols="2" class="pb-0">
							<v-select
								v-model="unit"
								:items="unitList"
								item-text="name"
								item-value="id"
								label="单元"
								clearable
								return-object
								@change="getAssetList('room', unit, 4)"
							/>
						</v-col>
						<v-col cols="2" class="pb-0">
							<v-select
								v-model="room"
								:items="roomList"
								item-text="name"
								item-value="id"
								label="房间"
								clearable
								return-object
								@change="getEquipmentList(room)"
							/>
						</v-col>
						<v-col cols="3" class="pb-0">
							<v-autocomplete
								v-model="select"
								:loading="loading"
								:items="items"
								item-value="id"
								item-text="text"
								:search-input.sync="search"
								hide-no-data
								hide-details
								clearable
								label="设备"
								placeholder=""
								return-object
								@click:clear="clearChoose('all')"
							/>
						</v-col>
						<v-col
							cols="1"
							class="pb-0 d-flex justify-end align-center"
						>
							<v-btn
								text
								color="primary"
								@click="cycleChoose"

							>
								<v-icon> mdi-magnify </v-icon></v-btn
							>
						</v-col>
					</v-row>
				</v-card-title>
        <v-card-text style=" height: calc(100vh - 222px); padding: 0; position: inherit" >
<!--          <Obv></Obv>-->
        </v-card-text>
			</v-card>
		</v-col>

		<v-col cols="3">
			<v-row>
				<v-col cols="12">
					<v-card flat color="cardBg" class="data-card">
						<v-card-title>
							<v-icon color="primary" class="mr-3">
								mdi-cash-multiple </v-icon
							><span class="font-title">电费</span>
						</v-card-title>
						<v-card-text>
							<v-row class="text-center">
								<v-col cols="4">
									<v-chip x-large color="success">
										<span
											class="font-digit"
											style="font-size: 2rem"
											>22</span
										>
										<span class="ml-1">%</span>
									</v-chip>
									<div class="mt-2">
										<v-icon color="success" size="30">
											mdi-trending-down
										</v-icon>
									</div>
									<div>节费率</div>
								</v-col>
								<v-col cols="4">
									<v-chip x-large color="primary">
										<span class="mr-1">¥</span>
										<span
											class="font-digit"
											style="font-size: 2rem"
											>2700</span
										>
									</v-chip>
									<div class="mt-2">
										<v-icon color="primary" size="30">
											mdi-fire
										</v-icon>
									</div>
									<div>集中供热费用</div>
								</v-col>
								<v-col cols="4">
									<v-chip x-large color="warning">
										<span class="mr-1">¥</span>
										<span
											class="font-digit"
											style="font-size: 2rem"
											>2100</span
										>
									</v-chip>
									<div class="mt-2">
										<v-icon color="warning" size="30">
											mdi-flash
										</v-icon>
									</div>
									<div>电供暖费用</div>
								</v-col>
							</v-row>
						</v-card-text>
						<v-divider />
					</v-card>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12">
					<v-card flat color="cardBg" class="data-card">
						<v-card-title>
							<v-icon color="primary" class="mr-3">
								mdi-file-sign </v-icon
							><span class="font-title">操作记录</span>
						</v-card-title>
						<v-card-text>
							<v-simple-table dense>
								<template v-slot:default>
									<thead>
										<tr>
											<th>时间</th>
											<th>事件</th>
											<th>详情</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(item, index) in records"
											:key="index"
										>
											<td>{{ item.time }}</td>
											<td>{{ item.event }}</td>
											<td>{{ item.details }}</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12">
					<v-card flat color="cardBg" class="data-card">
						<v-card-title>
							<v-icon color="primary" class="mr-3">
								mdi-account-settings
							</v-icon>
							<span class="font-title">用户习惯</span>
						</v-card-title>
						<v-card-text>
							<HabitChart class="chart" />
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
import TemperatureLineChart from "@/components/TemperatureLineChart";
import PowerLineChart from "@/components/PowerLineChart";
import DoubleAxesChart from "@/components/DoubleAxesChart";
import HabitChart from "@/components/HabitChart";
import { listAsset } from "@/api/tuya/asset";
import { listEquipment } from "@/api/tuya/equipment";
// import Obv from "@/components/Obv";

export default {
  name: "Dashboard",
  components: {
    // Obv,
    DoubleAxesChart,
    PowerLineChart,
    TemperatureLineChart,
    HabitChart,
  },
  data() {
    return {
      cycle: 7,
      power_cycle: 7,
      double_cycle: 7,
      records: [
        {
          time: "2022-05-26 11:26:00",
          event: "用户异常关机",
          details: "睡眠模式关机",
        },
        {
          time: "2022-05-26 11:26:00",
          event: "用户异常关机",
          details: "睡眠模式关机",
        },
        {
          time: "2022-05-26 11:26:00",
          event: "用户异常关机",
          details: "睡眠模式关机",
        },
        {
          time: "2022-05-26 11:26:00",
          event: "用户异常关机",
          details: "睡眠模式关机",
        },
      ],

      select: null,
      loading: false,
      items: [],
      search: null,

      communityList: [],
      community: null,

      floorList: [],
      floor: null,

      unitList: [],
      unit: null,

      roomList: [],
      room: null,
    };
  },
  created() {
    this.getAssetList("community", "null", 1);
  },
  mounted() {
  },
  methods: {
    getAssetList(category, object, level) {
      if (object === null) {
        switch (category) {
          case "floor":
            this.floor = null;
            this.floorList = [];
            this.unit = null;
            this.unitList = [];
            this.room = null;
            this.roomList = [];
            this.select = null;
            break;
          case "unit":
            this.unit = null;
            this.unitList = [];
            this.room = null;
            this.roomList = [];
            this.select = null;
            break;
          case "room":
            this.room = null;
            this.roomList = [];
            this.select = null;
            break;
        }
        return;
      }
      const params = {};
      if (object !== "null") {
        params["parent"] = object.id;
      }
      params["level"] = level;
      listAsset(params).then((res) => {
        switch (category) {
          case "community":
            this.communityList = res;
            this.floor = null;
            this.floorList = [];
            this.unit = null;
            this.unitList = [];
            this.room = null;
            this.roomList = [];
            break;
          case "floor":
            this.floorList = res;
            this.unit = null;
            this.unitList = [];
            this.room = null;
            this.roomList = [];
            break;
          case "unit":
            this.unitList = res;
            this.room = null;
            this.roomList = [];
            break;
          case "room":
            this.roomList = res;
        }
      });
    },

    getEquipmentList(room) {
      this.items = [];
      if (room !== null) {
        this.loading = true;
        listEquipment({ asset: room.id }).then((res) => {
          res.forEach((item) => {
            item.text = `${item.id} | ${item.category} | ${item.name}`;
            this.items.push(item);
          });
        });
      }
      this.select = null;
      this.loading = false;
    },

    clearChoose(choose) {
      switch (choose) {
        case "all":
          this.select = null;
          this.room = null;
          this.floor = null;
          this.unit = null;
          this.community = null;
          this.roomList = [];
          this.floorList = [];
          this.unitList = [];
          break;
        case "object":
          break;
      }
    },

    cycleChoose() {
      if (this.cycle === 7) {
        this.cycle = 1;
        this.power_cycle = 1;
        this.double_cycle = 1;
      } else {
        this.cycle = 7;
        this.power_cycle = 7;
        this.double_cycle = 7;
      }
    },
  },
};
</script>

<style scoped>
.data-card {
  height: calc((100vh - 180px) / 3);
}
.chart {
  height: calc(((100vh - 180px) / 3) - 80px);
}
</style>
