import request from "../../utils/request";


// 查询设备列表
export async function createTask(data) {
    return request({
    url: '/api/tuya/tasks/community/',
    method: 'post',
    data: data,
  })
}

// 查询设备列表
export async function retrieveWeather() {
    return request({
    url: '/api/tuya/tasks/weather/',
    method: 'get',
  })
}

// 停止定时任务
export async function stopSchedulerTask(data) {
    return request({
    url: '/api/tuya/tasks/stop/',
    method: 'post',
    data: data,
  })
}

// 删除定时任务
export async function deleteSchedulerTask(data) {
    return request({
    url: '/api/tuya/tasks/delete/',
    method: 'delete',
    data: data,
  })
}