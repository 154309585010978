import request from "../../utils/request";


// 查询设备列表
export async function EquipmentStatus(params) {
    return request({
    url: '/api/tuya/tuya/status/',
    method: 'get',
    params: params,
  })
}