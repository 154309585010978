import request from "@/utils/request";


// 查询资产列表
export async function listAsset(params) {
    return request({
    url: '/api/tuya/assets/',
    method: 'get',
    params: params,
  })
}


// 创建或更新资产
export async function createAsset() {
  return request({
    url: '/api/tuya/assets/',
    method: 'post',
  })
}
