<!--@Time : 2022/5/27 17:34-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<div id="power_container"></div>
</template>

<script>
import { Chart } from "@antv/g2";
import {listPower} from "@/api/tuya/statistics";

export default {
	name: "PowerLineChart",
	data() {
		return {
			chart: null,
			data: [],
		};
	},
  props: ["power_cycle"],
	created() {},
	mounted() {
    this.getListPower(this.power_cycle)
		this.initChart();
	},
  watch: {
    "power_cycle": {
      handler (newValue) {
        this.getListPower(newValue)
      },
    }
  },
	methods: {
		initChart() {
			this.chart = new Chart({
				container: "power_container",
				autoFit: true,
			});
			this.chart.data(this.data);
			this.chart.scale({
        day: ()=> {
          if (this.power_cycle === 1){
            return {
              range: [0, 1],
              type: 'time',
              mask: "YYYY-MM-DD HH:mm"
            }
          } else {
            return {
              range: [0, 1],
              type: 'time',
              mask: "YYYY-MM-DD"
            }
          }
        },
				power: {
					min: 0,
					max: 3,
				},
			});

			this.chart.tooltip({
				showCrosshairs: true,
				shared: true,
			});

			this.chart.axis("power", {
				label: {},
			});

			this.chart.legend({
				position: "bottom",
			});

			this.chart
				.line()
				.position("day*power")
				.color("type", (type) => {
					switch (type) {
						case "需求功率":
							return "#4CAF50";
						case "实际功率":
							return "#03A9F4";
						case "功率限额":
							return "#FFA000";
					}
				})
				.shape("smooth");

			this.chart.render();
		},

    getListPower(cycle) {
      listPower({cycle:cycle}).then( (res)=>{
        this.data = res
        this.chart.changeData(this.data)
      })
    },
  },
};
</script>

<style scoped>
</style>