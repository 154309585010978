import store from "@/store";

const system = {
    state: {
        barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
        barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
        drawer: null,
        showSettingsDialog: false,
        callbackInfo: null,
    },

    mutations: {
        SET_BAR_IMAGE (state, barImage) {
            state.barImage = barImage
        },
        SET_DRAWER (state, drawer) {
            state.drawer = drawer
        },
        SET_SHOW_SETTINGS_DIALOG (state, showSettingsDialog) {
            state.showSettingsDialog = showSettingsDialog
        },
        SET_BackWebSocket (state, callbackInfo){
          state.callbackInfo = callbackInfo
        },
    },

    actions: {
        initWebsocket( { commit } ){
            let url = `${process.env.VUE_APP_WS_URL}/ws/back/?token=${store.getters.token}`
            let callbackInfo = new WebSocket(url)
            commit('SET_BackWebSocket', callbackInfo)
        },
    }
}

export default system