import router from './router'
import {getToken} from "./utils/auth";


// 全局前置路由守卫
router.beforeEach((to, from, next) => {
    if (getToken()) {
        // 有token
        if (to.name === 'Login') {
            // 在登录页直接跳转到主页
            next({ name: 'Dashboard' })
        } else {
            next()
        }
    }
    else {
        if (to.name === 'Login') {
            next()
        } else {
            next({ path: '/' })  // 否则全部重定向到登录页
        }
    }
})
