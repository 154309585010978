import request from "../../utils/request";


// 查询设备列表
export async function listEquipment(params) {
    return request({
    url: '/api/tuya/equipments/',
    method: 'get',
    params: params,
  })
}


export async function QuerylistEquipment(params) {
    return request({
    url: '/api/tuya/equipments/querylist/',
    method: 'get',
    params: params,
  })
}


// 查询单个设备详情
export async function retrieveEquipment(eId) {
  return request({
    url: `/api/tuya/equipments/${eId}/`,
    method: 'get',
  })
}


// 创建设备
export async function createEquipment(data) {
  return request({
    url: '/api/tuya/equipments/',
    method: 'post',
    data: data
  })
}

// 创建单个设备
export async function createOneEquipment(data) {
  return request({
    url: '/api/tuya/equipments/add/',
    method: 'post',
    data: data
  })
}

// 更新设备
export async function updateEquipment(Id, data) {
    return request({
        url: `/api/tuya/equipments/${Id}/`,
        method: 'patch',
        data: data,
    })
}

// 更新设备
export async function retrieveAssetEquipment(Id) {
    return request({
        url: `/api/tuya/equipments/${Id}/asset/`,
        method: 'get',
    })
}



