<!--@Time : 2022/5/27 17:36-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<div id="power_consumption_container"></div>
</template>

<script>
import { Chart } from "@antv/g2";
import {listDouble} from "@/api/tuya/statistics";

export default {
	name: "DoubleAxesChart",
	data() {
		return {
			chart: null,
			data: [],
		};
	},
  props: ['double_cycle'],
	created() {},

	mounted() {
    this.getListDouble(this.double_cycle)
		this.initChart();
	},
  watch: {
    "double_cycle": {
      handler (newValue){
        this.getListDouble(newValue)
      },
    }
  },
	methods: {
    initChart() {
      this.chart = new Chart({
        container: "power_consumption_container",
        autoFit: true,
        height: 500,
      });
      this.chart.data(this.data);
      this.chart.scale({
        time: ()=>{
          if (this.double_cycle === 1){
            return {
              range: [0, 1],
              type: 'time',
              mask: "YYYY-MM-DD HH:mm"
            }
          } else {
            return {
              range: [0, 1],
              type: 'time',
              mask: "YYYY-MM-DD"
            }
          }
        },
        实际用电: {
          min: 0,
          max: 10,
        },
        预期用电: {
          min: 0,
          max: 10,
        },
      });
      this.chart.legend({
        custom: true,
        items: [
          {
            value: "waiting",
            name: "实际用电",
            marker: {
              symbol: "square",
              style: { fill: "#FFA000", r: 5 },
            },
          },
          {
            value: "people",
            name: "预期用电",
            marker: {
              symbol: "hyphen",
              style: { stroke: "#03A9F4", r: 5, lineWidth: 2 },
            },
          },
        ],
      });
      this.chart.tooltip({
        shared: true,
      });
      this.chart.interval().position("time*实际用电").color("#FFA000");
      this.chart
          .line()
          .position("time*预期用电")
          .color("#03A9F4")
          .size(2)
          .shape("dash");
      this.chart
          .point()
          .position("time*预期用电")
          .color("#03A9F4")
          .size(4)
          .shape("circle");

      this.chart.interaction("active-region");
      this.chart.removeInteraction("legend-filter"); // 自定义图例，移除默认的分类图例筛选交互
      this.chart.render();
    },

    getListDouble(cycle){
      listDouble({cycle: cycle}).then( (res)=> {
        this.data = res
        this.chart.changeData(this.data)
      })
    }
  },
};
</script>

<style scoped>
</style>