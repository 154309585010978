import {getToken, removeToken, setToken} from "@/utils/auth";
import {getInfo, login} from "@/api/login";
import router from "../../router";

const user = {
    state: {
        token: getToken(),
        username: null,
        user_id: null,
        groups:[],
    },

    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_NAME: (state, username) => {
            state.username = username
        },
        SET_USERID: (state, user_id) => {
            state.user_id = user_id
        },
        SET_USER_GROUPS: (state, groups) => {
            state.groups = groups
        },
    },

    actions: {
        // 登录
        Login({ commit }, userInfo) {
            const username = userInfo.username
            const password = userInfo.password
            return new Promise((resolve, reject) => {
                login(username, password).then( res => {
                    setToken("token", res.access)
                    commit('SET_TOKEN', res.access)
                    resolve()
                    router.push({ name: "Dashboard" }).then()
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 获取用户信息
        GetInfo({ commit} ) {
            return new Promise((resolve, reject) => {
                getInfo().then(res => {
                    commit('SET_NAME', res.username)
                    commit('SET_USERID', res.id)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },


        // 退出
        LogOut( { commit }) {
            removeToken("token")
            commit('SET_SHOW_SETTINGS_DIALOG', false)
            router.push({name: "Login"}).then().catch()
        },
    }
}

export default user
