import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import { Message, Notification } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/css/scrollbar.css"

Vue.config.productionTip = false

// 全局前置路由守卫
import './permission'

// 按需导入element-ui
Vue.prototype.$message = Message;
Vue.prototype.$notify = Notification;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
