<!--@Time : 2022/5/26 15:38-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<v-main class="bg">
		<v-container class="fill-height" fluid>
      <dashboard></dashboard>
			<!-- <v-row
          align="center"
          justify="center"
      >
        <v-col class="text-center">
          <router-view></router-view>
        </v-col>
      </v-row> -->
		</v-container>
	</v-main>
</template>

<script>
import Dashboard from "../../views/Dashboard";
export default {
	name: "Main",
	components: { Dashboard },
};
</script>

<style scoped>
</style>