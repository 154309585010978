import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from 'vuetify/lib/locale/en'

Vue.use(VueI18n)

const messages = {
  en: {
    ...require('../i18n/language/en.json'),
    $vuetify: en,
  },
}

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',

  // 隐藏警告
  silentTranslationWarn: true,
  messages,
})

export default i18n
