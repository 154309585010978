<!--@Time : 2022/5/31 12:40-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
  <v-row>
    <v-col
      cols="6"
      class="py-0"
    >
      <v-text-field
        :label="label_1"
        v-model="time_1"
        type="time"
        prefix="起"
        @blur="timer"
      />
    </v-col>

    <v-col
      cols="6"
      class="py-0"
    >
      <v-text-field
        :label="label_1"
        v-model="time_2"
        type="time"
        prefix="止"
        @blur="timer"
      />
    </v-col>

    <v-col
      cols="6"
      class="py-0"
    >
      <v-text-field
        :label="label_2"
        suffix="℃"
        v-model="correction"
        @blur="timer"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
	name: "TimePicker",
	data() {
		return {
			label_1: `时段${this.index + 1}`,
			label_2: `时段${this.index + 1}修正`,
      time_1: this.item.time_1,
      time_2: this.item.time_2,
      correction: this.item.correction,
		};
	},
	props: ["item", "number", "index"],
  watch: {
    number(newValue, oldValue){
      if (newValue > oldValue) {
        if (newValue !== 1){
          this.time_2 = null
        } else {
          this.time_2 = "23:59"
        }
      } else {
       if (this.item.index === newValue){
         this.time_2 = "23:59"
       } else {
         this.time_2 = null
       }
      }
    },
  },
  methods: {
    timer(){
      if (this.time_1 !== null && this.time_2 !== null && this.correction !== null){
        this.$emit("update", { time_1: this.time_1, time_2: this.time_2, correction: this.correction, index: this.item.index })
      }
    }
  }
};
</script>

